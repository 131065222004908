export const GET_CUSTOMER_DETAILS = 'GET_CUSTOMER_DETAILS'
export const SET_CUSTOMER = 'SET_CUSTOMER'
export const SAVE_CUSTOMER = 'SAVE_CUSTOMER'
export const GET_CUSTOMER_DEBT = 'GET_CUSTOMER_DEBT'
export const CLEAR_CUSTOMER_EMAIL = 'CLEAR_CUSTOMER_EMAIL'
export const SET_CUSTOMER_CONTACT = 'SET_CUSTOMER_CONTACT'
export const CONFIRM_CUSTOMER_BILLING_ADDRESS =
  'CONFIRM_CUSTOMER_BILLING_ADDRESS'
export const SET_MULTI_SITE_DETAILS = 'SET_MULTI_SITE_DETAILS'

export function getCustomerDetails(payload) {
  return {
    type: GET_CUSTOMER_DETAILS,
    payload
  }
}

export function setCustomerDetails(details) {
  return {
    type: SET_CUSTOMER,
    payload: {
      details: details
        ? {
            ...details,
            isUpdated: false
          }
        : details
    }
  }
}

export function updateCustomerDetails(details) {
  return {
    type: SET_CUSTOMER,
    payload: {
      details: {
        ...details,
        isUpdated: true
      }
    }
  }
}

export function saveCustomer(customer) {
  return {
    type: SAVE_CUSTOMER,
    payload: { customer }
  }
}

export function clearCustomerEmail() {
  return {
    type: CLEAR_CUSTOMER_EMAIL
  }
}

export function setCustomerContact(contact) {
  return {
    type: SET_CUSTOMER_CONTACT,
    payload: { contact }
  }
}

export function getCustomerDebt(sessionId) {
  return {
    type: GET_CUSTOMER_DEBT,
    payload: { sessionId }
  }
}

export function setCustomerDebt(debt) {
  return {
    type: SET_CUSTOMER,
    payload: { debt }
  }
}

export function confirmCustomerBillingAddress(payload) {
  return {
    type: CONFIRM_CUSTOMER_BILLING_ADDRESS,
    payload
  }
}

export function setMultiSiteDetails(payload) {
  return {
    type: SET_MULTI_SITE_DETAILS,
    payload
  }
}
