export const orderSubmitActions = {
  orderSubmit: 'ORDERSUBMIT',
  validateCalendar: 'VALIDATEINSTALL',
  getCalendar: 'GETCALENDAR'
}

export const cbmEligibilityTypes = {
  notEligible: 'NOT_ELIGIBLE',
  taxExemptEligible: 'ELIGIBLE_TAX_EXEMPT',
  noInternetEligible: 'ELIGIBLE_NO_INTERNET',
  eligible: 'ELIGIBLE',
  addedCBM: 'ADDED_CBM',
  hasCbm: 'HAS_CBM'
}

export const cbmStatusTypes = {
  isEligible: 'isEligible',
  notEligible: 'notEligible',
  hasCbm: 'hasCbm'
}

const ELIGIBLE_TAX_EXEMPT = {
  messages: [
    'Tax Exempt Customers are not eligible for Comcast Business Mobile.'
  ],
  messageType: 'warning'
}

const ADDED_CBM = {
  messages: [
    "To finish submitting the customer's CBM order, select Launch CBM below."
  ],
  messageType: 'success'
}

export const cbmMessages = {
  ELIGIBLE: {
    configure: {
      messages: ['Customer qualifies for CBM.'],
      messageType: 'success'
    }
  },
  ELIGIBLE_NO_INTERNET: {
    configure: {
      messages: [
        'Customer meets the criteria for CBM eligibility. Return to PEO Page to select an offer with Business Internet to add CBM to the order.'
      ],
      messageType: 'warning'
    }
  },
  ELIGIBLE_TAX_EXEMPT: {
    offers: ELIGIBLE_TAX_EXEMPT,
    configure: ELIGIBLE_TAX_EXEMPT,
    documents: ELIGIBLE_TAX_EXEMPT,
    submit: ELIGIBLE_TAX_EXEMPT
  },
  ADDED_CBM: {
    submit: ADDED_CBM,
    documents: null,
    success: null,
    dashboard: null
  }
}
export const banErrorMessage = 'BAN could not be fetched due to application error, please proceed with manual process or try again later'
export const banNotFoundMessage = 'BAN could not be found, please confirm existing BAN'
