import * as actions from '../actions'

export const initialState = {
  details: undefined
}

function customerReducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case actions.SET_CUSTOMER: {
      return {
        ...state,
        ...payload
      }
    }

    case actions.CLEAR_CUSTOMER_EMAIL: {
      const { details } = state

      return {
        ...state,
        details: {
          ...details,
          contact: {
            ...details.contact,
            email: '',
            hasOptedOutOfEmail: false
          }
        }
      }
    }

    case actions.SET_CUSTOMER_CONTACT: {
      const { details } = state
      const { contact } = payload

      return {
        ...state,
        details: {
          ...details,
          contact: {
            ...details.contact,
            ...contact
          }
        }
      }
    }

    case actions.SET_MULTI_SITE_DETAILS: {
      const { details } = state

      return {
        ...state,
        details: {
          ...details,
          multiSite: {
            ...details.multiSite,
            ...payload
          }
        }
      }
    }

    default: {
      return state
    }
  }
}

export default customerReducer
