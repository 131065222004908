import {
  takeLatest,
  take,
  put,
  call,
  select,
  delay,
  all
} from 'redux-saga/effects'
import * as actions from '../actions'
import * as service from '../services/order'
import { errors } from '../constants/content'
import { handleRedirectError } from '../utils/error'
import { orderRouteIds } from '../constants/navigation'
import { workflowStepIds, appTypes } from '../constants/session'
import { selectOrderSubmit } from '../selectors/order'
import { selectActiveForm } from '../selectors/form'
import { getValidationError } from '../utils/validations/core'
import orderValidations, {
  validateOrderSubmit
} from '../utils/validations/order'
import { selectCustomerDetails } from '../selectors/customer'
import { getSaveCustomerPayload } from '../utils/customer'
import { updateCustomerInfo } from '../services/customer'
import {
  timeSlot,
  orderSubmitError,
  abtMessage,
  saveMove
} from '../constants/form'
import { orderSubmitActions } from '../constants/order'
import { getFilteredCalendar } from '../utils/scheduling'
import { orderSubmitMessages } from '../constants/loading'
import { isSameWorkflowStepRange } from '../utils/session'
import { rpcConnection } from '../utils/rpc'
import { getEnv } from '../utils/env'
import { getSaveMoveDetailsRequest } from '../utils/order'
import { selectActivePageId } from '../selectors/router'
import {
  selectConfiguredVoiceLines,
  selectHasVoiceConfiguration,
  selectIsVoiceComcastTNselected,
  selectVoiceConfiguredItems
} from '../selectors/configuration'
import { voiceSections } from '../constants/configuration'
import { getUpdateTollFreeLines } from '../utils/configuration'
import * as documentServices from '../services/documents'

const { ORDER_REJECTED, ORDER_SUBMITTED, ORDER_PENDING } = workflowStepIds

const saveActions = {
  [orderRouteIds.offers]: actions.selectOffer,
  [orderRouteIds.configure]: actions.saveCart,
  [orderRouteIds.documents]: actions.checkDocumentAcceptance,
  [orderRouteIds.dashboard]: actions.navigateToContinueOrderRoute,
  [orderRouteIds.submit]: actions.validateOrder
}

export function* getOrderSaga({ payload }) {
  yield put(actions.initializeWorkflowStep(payload))
  yield put(actions.getCustomerDetails(payload))
  yield put(actions.getOrderServices(payload))
}

export function* getOrderServicesSaga({ payload = {} }) {
  const { HOST_ENV } = getEnv()

  try {
    const { buyflowContext, services, additionalServices } = yield call(
      service.getOrderServices,
      payload
    )
    const appType = buyflowContext?.customerType.toUpperCase()

    if (appType) {
      yield put(
        actions.setSessionData({
          appType,
          sourceSystem: buyflowContext?.sourceSystem
        })
      )
    }

    if (services?.isLimitBBRoutingEnabled) {
      yield put(actions.getBundleBuilderData({ showSpinner: false }))
      yield put(
        actions.setSessionData({
          isLimitBBRoutingEnabled: services.isLimitBBRoutingEnabled
        })
      )
    }

    if (services?.showBundleBuilderLink) {
      yield put(
        actions.setSessionData({
          showBundleBuilderLink: services.showBundleBuilderLink
        })
      )
    }

    yield put(actions.getCustomerDebt(services.sessionId))
    yield put(actions.setSessionId(services.sessionId))
    yield put(actions.updateOrder(additionalServices))

    if (appType === appTypes.NC) {
      yield put(actions.setExistingServices({}))
    } else {
      yield put(actions.setExistingServices(services))
    }
  } catch (error) {
    // TODO: Needs discussion since opp id won't be returned in the future
    handleRedirectError(error, payload.opportunityId)

    if (HOST_ENV === 'local') {
      yield put(actions.setExistingServices({}))
    }
  }
}

export function* saveOrderSaga({ payload }) {
  const state = yield select()
  const form = yield select(selectActiveForm)
  const customer = yield select(selectCustomerDetails)
  const { params } = yield select(({ router }) => router.location.match)
  const { opportunityId, sessionId, fxbuyflowSessionId } = yield select(
    ({ session }) => session
  )
  const { pageId } = params
  const saveAction = saveActions[pageId]

  const { error } = getValidationError(orderValidations.validations.order, {
    state,
    pageId,
    payload
  })

  const hasFormErrors = !!form.errors.customerPrimaryEmail

  if (hasFormErrors) {
    return yield put(
      actions.setFormErrorAlert('customerEmailAlert', {
        message: errors.invalidCustomerEmail
      })
    )
  }

  if (error) {
    return yield put(
      actions.setFormErrorAlert('customerVesValidation', {
        message: error.message
      })
    )
  }

  if (customer.isUpdated) {
    yield put(actions.togglePageLoading())

    try {
      const updatedCustomerPayload = getSaveCustomerPayload(customer)

      yield call(updateCustomerInfo, updatedCustomerPayload, {
        opportunityId,
        sessionId,
        fxbuyflowSessionId
      })
      yield put(actions.setCustomerDetails(customer))
    } catch {
      // Ignore error since API logging will catch it
    } finally {
      yield put(actions.togglePageLoading(false))
    }
  }

  if (saveAction) {
    yield put(saveActions[pageId]({ ...payload, form }))
  } else if (form.isValid) {
    yield put(actions.navigateToNextOrderRoute(pageId))
  }

  return true
}

export function* getOrderStatusSaga() {
  const { opportunityId, sessionId, fxbuyflowSessionId } = yield select(
    ({ session }) => session
  )

  try {
    const status = yield call(service.getOrderStatus, {
      opportunityId,
      sessionId,
      fxbuyflowSessionId
    })
    yield put(actions.setOrderStatus(status))
  } catch {
    yield put(actions.setOrderStatus(null))
  }
}

export function* getDocumentTypesSaga() {
  try {
    const docTypes = yield call(service.getDocumentTypes)
    // Assume that the order of the Document types that are received in is the order to display them in
    // this provides better scalability so that they don't have to update any weights they can just insert where needed
    // add an order property for sorting and display
    const weightedDocTypes = docTypes.map((type, order) => ({ ...type, order }))

    yield put(actions.setDocumentTypes(weightedDocTypes))
  } catch {
    throw new Error('Could not retrieve document types')
  }
}

export function* getOrderDashboardSaga() {
  const { workflowStepId } = yield select(({ session }) => session)

  yield put(actions.togglePageLoading())

  yield put(actions.getOrderStatus())
  yield put(actions.getCartDetails())
  yield put(actions.getDocumentTypes())
  yield put(actions.getDocuments())

  if (isSameWorkflowStepRange(workflowStepId, ORDER_REJECTED)) {
    yield put(actions.getOrderDetails())
  }

  yield put(actions.togglePageLoading(false))
}

export function* getOrderDetailsSaga() {
  const { opportunityId, sessionId, fxbuyflowSessionId } = yield select(
    ({ session }) => session
  )

  try {
    yield put(actions.togglePageLoading())
    const {
      orderSubmitRequest,
      isPrewireFailure = false,
      prewireFailureMessage
    } = yield call(service.getOrderDetails, {
      opportunityId,
      sessionId,
      fxbuyflowSessionId
    })
    yield put(
      actions.setOrderDetails({
        ...orderSubmitRequest,
        isPrewireFailure,
        ...(isPrewireFailure && { prewireFailureMessage })
      })
    )
  } finally {
    yield put(actions.togglePageLoading(false))
  }
}

export function* validateOrderSaga() {
  yield put(actions.clearFormError(timeSlot))
  yield put(actions.clearFormError(orderSubmitError))
  yield put(actions.clearFormError(abtMessage))

  // Need to wait for errors to be cleared before selecting
  yield take(actions.UPDATE_FORM)
  const error = yield select(validateOrderSubmit)

  if (error) {
    return yield put(
      actions.setFormErrorAlert(orderSubmitError, {
        message: error
      })
    )
  }

  const { apiUrl, apiAction, isFullAutomationOrder } = yield select(
    ({ scheduling }) => scheduling?.calendar
  )

  if (apiAction === orderSubmitActions.orderSubmit) {
    return yield put(actions.submitOrder(apiUrl, isFullAutomationOrder))
  }

  // Handled case when API is either validateInstall or verifyAndReviewCart
  return yield put(actions.validateOrderInstall(apiUrl))
}

export function* submitOrderSaga({ payload }) {
  const { apiUrl, isFullAutomationOrder } = payload
  const orderData = yield select(selectOrderSubmit)
  const { fxbuyflowSessionId } = yield select(state => state.session)
  try {
    yield put(actions.togglePageLoading(orderSubmitMessages.submitOrder))

    if (isFullAutomationOrder) {
      const { accessToken } = yield select(({ session }) => session)
      rpcConnection.build(accessToken)
      yield call(rpcConnection.start)
    }

    yield call(service.refreshOpportunityDetails, fxbuyflowSessionId)
    const res = yield call(service.processOrder, apiUrl, orderData, true)

    if (res?.groupId) {
      yield put(
        actions.updatePageLoadingMessage(
          orderSubmitMessages.waitingConfirmation
        )
      )
      yield put(
        actions.openRPCConnection({
          connectionId: res?.groupId,
          onSendAction: actions.SYNC_ORDER_SUBMIT_ON_MESSAGE
        })
      )
    } else {
      const isSuccess = res?.status?.toLowerCase() === 'success'

      rpcConnection.stop()
      yield put(
        actions.setSessionData({
          workflowStepId: isSuccess ? ORDER_SUBMITTED : ORDER_PENDING
        })
      )
      yield put(actions.submitOrderSuccess())
    }
  } catch (error) {
    // TODO: Need to discuss how to handle
    yield put(actions.togglePageLoading(false))
  }
}

export function* submitOrderSuccessSaga() {
  yield put(actions.navigateToOrderRoute(orderRouteIds.success))
  return yield put(actions.togglePageLoading(false))
}

export function* validateOrderInstallSaga({ payload }) {
  const { apiUrl } = payload

  yield put(actions.togglePageLoading(orderSubmitMessages.validateInstall))
  const orderData = yield select(selectOrderSubmit)
  const data = yield call(service.processOrder, apiUrl, orderData)

  if (data.hasCalendars) {
    yield put(
      actions.setGlobalWarning(timeSlot, data.newTimeslotSelectionMessage)
    )
    yield put(actions.setCalendar(getFilteredCalendar(data)))
    yield put(actions.togglePageLoading(false))
  } else {
    yield put(actions.togglePageLoading(false))
    yield put(actions.submitOrder(data.apiUrl, data.isFullAutomationOrder))
  }
}

export function* syncOrderSubmitMessageHandlerSaga({ payload }) {
  const { connectionId, data } = payload

  yield put(actions.closeRPCConnection(connectionId))

  if (data?.apiName === orderSubmitActions.getCalendar) {
    yield put(actions.togglePageLoading(false))
    yield put(actions.getCalendar())
  } else {
    const isSuccess = data?.orderStatus?.toLowerCase() === 'success'

    yield put(
      actions.setSessionData({
        workflowStepId: isSuccess ? ORDER_SUBMITTED : ORDER_PENDING
      })
    )
    yield put(actions.submitOrderSuccess())
  }
}

export function* getProjectCodesSaga({ payload }) {
  const { searchTerm, searchType } = payload
  const { fxbuyflowSessionId } = yield select(state => state.session)

  yield put(actions.setProjectCodes(null))
  const projectCodes = yield call(
    service.getProjectCodes,
    fxbuyflowSessionId,
    searchTerm,
    searchType
  )
  yield put(actions.setProjectCodes(projectCodes))
}

export function* getOrderMoveDetailsSaga() {
  const { fxbuyflowSessionId } = yield select(state => state.session)

  yield put(actions.togglePageLoading())
  yield put(actions.updateOrder({ orderMoveDetails: {} }))
  const orderMoveDetails = yield call(
    service.getOrderMoveDetails,
    fxbuyflowSessionId
  )

  const { existingServices } = orderMoveDetails

  yield put(actions.updateOrder({ orderMoveDetails }))
  if (existingServices) {
    yield put(actions.setExistingServices(existingServices))
  }
  yield put(actions.togglePageLoading(false))
}

export function* saveOrderMoveDetailsSaga({ payload }) {
  const { opportunityId, fxbuyflowSessionId } = yield select(
    ({ session }) => session
  )
  const { orderMoveDetails, redirectToConfig = false } = payload
  const { isMoveSelected } = orderMoveDetails
  const pageId = yield select(selectActivePageId)
  const hasVoiceConfig = yield select(selectHasVoiceConfiguration)
  const { isBanCheckMoveOrder = false } = orderMoveDetails || {}

  // ban check
  if (isBanCheckMoveOrder) {
    return yield put(
      actions.saveOrderBanMoveDetails({
        ...orderMoveDetails,
        fxbuyflowSessionId
      })
    )
  }

  const request = getSaveMoveDetailsRequest({
    ...orderMoveDetails,
    opportunityId,
    fxbuyflowSessionId,
    redirectToConfig
  })

  try {
    yield put(actions.togglePageLoading())
    yield put(actions.toggleMoveModal())
    const res = yield call(service.saveOrderMoveDetails, request)

    if (res) {
      yield put(
        actions.setGlobalSuccess(saveMove, 'Move details saved successfully.')
      )

      if (
        pageId === orderRouteIds.configure &&
        hasVoiceConfig &&
        !isMoveSelected
      ) {
        const { voiceLines, tollFree } = yield select(
          selectConfiguredVoiceLines
        )

        // when voice section is not configured yet
        if (voiceLines.length === 0) {
          const voiceConfiguredItems = yield select(selectVoiceConfiguredItems)
          const { primaryLine, additionalLines } = voiceConfiguredItems
          const updatedAdditionalLines = additionalLines.map(line =>
            line.isComcastTN
              ? {
                  ...line,
                  isComcastTN: false,
                  phoneNumber: '',
                  isCRC: false
                }
              : line
          )

          const updatedConfigItems = {
            ...voiceConfiguredItems,
            primaryLine: {
              ...primaryLine,
              ...(primaryLine.isComcastTN && {
                isComcastTN: false,
                isCRC: false,
                phoneNumber:
                  primaryLine.isComcastTN && !!primaryLine.phoneNumber.trim()
                    ? ''
                    : primaryLine.phoneNumber
              })
            },
            additionalLines: updatedAdditionalLines
          }

          yield put(
            actions.setConfig({
              ...updatedConfigItems
            })
          )
        } else {
          // voice is configured and needs to be re-validated.
          yield all(
            voiceLines.map(voiceLine =>
              put(
                actions.setVoiceLine({
                  ...voiceLine,
                  isComcastTN: false,
                  phoneNumber: voiceLine.isComcastTN
                    ? ''
                    : voiceLine.phoneNumber,
                  ...(voiceLine.isComcastTN && { isCRC: false })
                })
              )
            )
          )

          if (tollFree.length) {
            const tnLineIds = voiceLines
              .map(voiceLine => (voiceLine.isComcastTN ? voiceLine.lineId : ''))
              .filter(Boolean)

            const newTollFreeLines = getUpdateTollFreeLines(tollFree, tnLineIds)

            if (newTollFreeLines[0]?.length > 0) {
              yield all(
                newTollFreeLines[0].map(tollFreeLine =>
                  put(
                    actions.setVoiceLine({
                      ...tollFreeLine,
                      callingAreaDestination: ['USA'],
                      ringToNumberLine: {}
                    })
                  )
                )
              )
            }
          }

          yield put(
            actions.setLastUpdatedVoiceSection(voiceSections.configOptions)
          )
        }
      }

      // re-run validations for voice sections when selected
      if (
        pageId === orderRouteIds.configure &&
        hasVoiceConfig &&
        isMoveSelected
      ) {
        yield put(
          actions.setLastUpdatedVoiceSection(voiceSections.configOptions)
        )
      }

      if (pageId === orderRouteIds.documents) {
        if (redirectToConfig) {
          yield put(actions.updateOrder({ orderMoveDetails }))
          yield put(actions.navigateToOrderRoute(orderRouteIds.configure))
          yield put(actions.clearFormError(saveMove))
          yield put(
            actions.setSessionData({
              workflowStepId: workflowStepIds.PENDING_CONFIGURATION
            })
          )
        } else {
          yield put(actions.refreshDocumentAcceptance())
        }
      }
    }
  } catch (error) {
    yield put(
      actions.setGlobalError(
        saveMove,
        'A technical error has occurred while saving move details. Please retry.'
      )
    )
    throw new Error(error.message)
  } finally {
    yield put(actions.togglePageLoading(false))
    yield put(actions.updateOrder({ orderMoveDetails }))

    yield delay(5000)
    yield put(actions.clearFormError(saveMove))
  }
  return true
}

export function* setOrderMoveComcastTnSaga() {
  const { orderMoveDetails } = yield select(state => state.order)
  const comcastTnSelected = yield select(selectIsVoiceComcastTNselected)

  if (comcastTnSelected) {
    yield put(
      actions.updateOrder({
        orderMoveDetails: { ...orderMoveDetails, isComcastTN: true }
      })
    )
  } else {
    yield put(
      actions.updateOrder({
        orderMoveDetails: { ...orderMoveDetails, isComcastTN: false }
      })
    )
  }
}

export function* getOrderMoveExistingServicesSaga({ payload }) {
  const { fxbuyflowSessionId } = yield select(({ session }) => session)
  const { orderMoveDetails } = yield select(state => state.order)

  yield put(actions.togglePageLoading('Searching existing services...'))
  yield put(actions.setExistingServices({}))
  yield put(
    actions.updateOrder({
      orderMoveDetails: {
        ...orderMoveDetails,
        movesBanMessage: '',
        billingAccountNumber: payload.banNumber
      }
    })
  )

  try {
    const { services, message, existingLocationInfo } = yield call(
      service.getOrderMoveExistingServices,
      {
        ...payload,
        fxbuyflowSessionId
      }
    )

    // set existing services
    if (services) {
      yield put(actions.setExistingServices(services))
      yield put(
        actions.updateOrder({
          orderMoveDetails: {
            ...orderMoveDetails,
            movesBanMessage: '',
            billingAccountNumber: payload.banNumber,
            existingLocationInfo,
            isOpenOrder: false
          }
        })
      )
    }

    // set ban message when not found
    if (message) {
      yield put(
        actions.updateOrder({
          orderMoveDetails: {
            ...orderMoveDetails,
            billingAccountNumber: payload.banNumber,
            movesBanMessage: message,
            isOpenOrder: false
          }
        })
      )
    }
  } finally {
    yield put(actions.togglePageLoading(false))
  }
}

export function* resetMoveBanCheckSaga() {
  const { orderMoveDetails } = yield select(state => state.order)

  if (!orderMoveDetails?.isBanCheckMoveOrder) {
    yield put(actions.setExistingServices({}))
  }
}

export function* saveOrderBanMoveDetailsSaga({ payload }) {
  const { orderMoveDetails } = yield select(state => state.order)
  const { fxbuyflowSessionId, ...banMoveDetails } = payload
  const pageId = yield select(selectActivePageId)

  try {
    yield put(actions.togglePageLoading())

    const { sessionId, isOpenOrder = false } = yield call(
      service.saveOrderBanMoveDetails,
      payload
    )

    // new sessionId returned and no open order proceed with UI updates
    if (sessionId && !isOpenOrder) {
      yield put(
        actions.updateOrder({
          orderMoveDetails: {
            ...orderMoveDetails,
            ...banMoveDetails,
            movesBanMessage: '',
            isMoveOrder: true,
            isAdvancedMoveOrder: true
          }
        })
      )
      yield put(actions.toggleMoveModal())
      yield put(actions.setSessionData({ sessionId }))

      // reset offer results to refetch filters
      yield put(
        actions.updateOffer({
          results: [],
          viewedOffers: {},
          refetchMoveOffers: true
        })
      )

      if (pageId === orderRouteIds.offers) {
        yield put(actions.getOfferDefaults())
      } else {
        yield put(actions.navigateToOrderRoute(orderRouteIds.offers))
      }
    }

    if (isOpenOrder) {
      yield put(
        actions.updateOrder({
          orderMoveDetails: {
            ...orderMoveDetails,
            ...banMoveDetails,
            isBanCheckMoveOrder: false,
            isOpenOrder: true,
            movesBanMessage:
              'This BAN is associated with an open order. Please proceed with the Manual Move process.'
          }
        })
      )
    }
  } finally {
    yield put(actions.togglePageLoading(false))
  }
}

export function* cancelBanMoveSaga() {
  const { orderMoveDetails } = yield select(state => state.order)
  const { fxbuyflowSessionId, sessionId, opportunityId } = yield select(
    ({ session }) => session
  )
  const pageId = yield select(selectActivePageId)

  try {
    yield put(actions.togglePageLoading())

    if (pageId === orderRouteIds.documents) {
      yield call(
        documentServices.expireDocuments,
        opportunityId,
        sessionId,
        fxbuyflowSessionId
      )
    }
    const newSessionId = yield call(service.cancelOrderBanMoveDetails, {
      fxbuyflowSessionId,
      billingAccountNumber: orderMoveDetails.billingAccountNumber,
      xcosSessionId: sessionId
    })

    if (newSessionId) {
      yield put(
        actions.updateOrder({
          orderMoveDetails: {
            ...orderMoveDetails,
            isBanCheckMoveOrder: false,
            isBanNameChangeSelected: false,
            billingAccountNumber: '',
            movesBanMessage: '',
            isMoveOrder: false,
            existingServices: {},
            isAdvancedMoveOrder: false
          }
        })
      )
      yield put(actions.setExistingServices({}))
      yield put(actions.setSessionData({ sessionId: newSessionId }))

      // reset offer results to refetch filters
      yield put(
        actions.updateOffer({
          results: [],
          viewedOffers: {},
          refetchMoveOffers: true
        })
      )

      if (pageId === orderRouteIds.offers) {
        yield put(actions.getOfferDefaults())
      } else {
        yield put(actions.navigateToOrderRoute(orderRouteIds.offers))
      }
    }
  } finally {
    yield put(actions.togglePageLoading(false))
  }
}

function* rootSaga() {
  yield takeLatest(actions.GET_ORDER, getOrderSaga)
  yield takeLatest(actions.GET_ORDER_SERVICES, getOrderServicesSaga)
  yield takeLatest(actions.SAVE_ORDER, saveOrderSaga)
  yield takeLatest(actions.GET_ORDER_STATUS, getOrderStatusSaga)
  yield takeLatest(actions.GET_DOCUMENT_TYPES, getDocumentTypesSaga)
  yield takeLatest(actions.GET_ORDER_DASHBOARD, getOrderDashboardSaga)
  yield takeLatest(actions.GET_ORDER_DETAILS, getOrderDetailsSaga)
  yield takeLatest(actions.VALIDATE_ORDER, validateOrderSaga)
  yield takeLatest(actions.VALIDATE_ORDER_INSTALL, validateOrderInstallSaga)
  yield takeLatest(actions.SUBMIT_ORDER, submitOrderSaga)
  yield takeLatest(actions.SUBMIT_ORDER_SUCCESS, submitOrderSuccessSaga)
  yield takeLatest(
    actions.SYNC_ORDER_SUBMIT_ON_MESSAGE,
    syncOrderSubmitMessageHandlerSaga
  )
  yield takeLatest(actions.GET_PROJECT_CODES, getProjectCodesSaga)
  yield takeLatest(actions.GET_ORDER_MOVE_DETAILS, getOrderMoveDetailsSaga)
  yield takeLatest(actions.SAVE_ORDER_MOVE_DETAILS, saveOrderMoveDetailsSaga)
  yield takeLatest(actions.SET_ORDER_MOVE_COMCAST_TN, setOrderMoveComcastTnSaga)
  yield takeLatest(
    actions.GET_MOVE_EXISTING_SERVICES,
    getOrderMoveExistingServicesSaga
  )
  yield takeLatest(actions.RESET_MOVE_BAN_CHECK, resetMoveBanCheckSaga)
  yield takeLatest(
    actions.SAVE_ORDER_BAN_MOVE_DETAILS,
    saveOrderBanMoveDetailsSaga
  )
  yield takeLatest(actions.CANCEL_BAN_MOVE, cancelBanMoveSaga)
}

export default rootSaga
