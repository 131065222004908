export const configTypes = {
  internet: 'internet',
  voice: 'voice',
  tv: 'tv',
  office: 'smartoffice',
  mobile: 'mobile'
}

export const boltOnTitles = {
  tv: 'TV',
  smartoffice: 'SmartOffice'
}

export const installLineItemTypes = {
  selfInstall: 'SELF_INSTALL',
  proInstall: 'PRO_INSTALL',
  additionalProInstall: 'PRO_INSTALL_ADDITIONAL'
}

export const VALID_INSTALL_TYPES = [
  installLineItemTypes.proInstall,
  installLineItemTypes.selfInstall
]

// Internet
export const professionalInstallId = 'SHOPPING_ELEMENT:90600060'

export const changeOfServiceId = 'SHOPPING_ELEMENT:90400162'

export const internetLineItemTypes = {
  securityEdge: 'SECURITY_EDGE',
  connectionPro: 'CONNECTION_PRO',
  wifi: 'BUSINESS_WIFI',
  wifiAP: 'BUSINESS_WIFI_AP',
  staticIps: 'BUSINESS_STATIC_IP',
  internetEquipment: 'DATA_EQUIP',
  highSpeedData: 'HSD_TIER',
  connectionProExtBat: 'CONNECTION_PRO_EXT_BAT',
  securityEdgeExtCov: 'SECURITY_EDGE_EXT_COV',
  selectedTierSpeed: 'SELECTER_TIER_SPEED',
  changeOfServiceWaiver: 'COS',
  truckRollOveride: 'TRUCK_ROLL_OVERRIDE'
}

// Unique configuration item ids
export const internetConfigurationItems = {
  noStaticIp: 'SHOPPING_ELEMENT:20400118',
  thirteenStaticIps: 'SHOPPING_ELEMENT:2007',
  fiveStaticIps: 'SHOPPING_ELEMENT:2013',
  oneStaticIp: 'SHOPPING_ELEMENT:3226',
  customerOwnedEquipment: 'SHOPPING_ELEMENT:20500027',
  docsis: 'SHOPPING_ELEMENT:20500040',
  businessWirelessGateway: 'SHOPPING_ELEMENT:20500019',
  internetEquipmentPackage: 'SHOPPING_ELEMENT:20500089',
  comcastProvidedEquipment: 'SHOPPING_ELEMENT:20500061',
  securityEdge: 'SHOPPING_ELEMENT:20400161',
  connectionPro: 'SHOPPING_ELEMENT:20400126',
  noWifi: 'SHOPPING_ELEMENT:20400112',
  wifiStandard: 'SHOPPING_ELEMENT:20400024',
  wifiPro: 'SHOPPING_ELEMENT:20400090',
  wifiPro2: 'SHOPPING_ELEMENT:20400091',
  giftCard200: 'SHOPPING_ELEMENT:90400079',
  giftCard400: 'SHOPPING_ELEMENT:90400080',
  proInstall: professionalInstallId,
  halfInstall: professionalInstallId,
  waivedInstall: professionalInstallId,
  selfInstallPriority: 'SHOPPING_ELEMENT:10600171',
  selfInstallStandard: 'SHOPPING_ELEMENT:10600148',
  connectionProExtBat: 'SHOPPING_ELEMENT:20400237',
  noConnectionPro: 'SHOPPING_ELEMENT:20400201',
  securityEdgeExtCov: 'SHOPPING_ELEMENT:20400271',
  noSecurityEdge: 'SHOPPING_ELEMENT:20400202',
  changeOfServiceWaiver: 'SHOPPING_ELEMENT:90400379',
  noTruckRollOverride: 'SHOPPING_ELEMENT:90400501',
  forceNoTruckOverride: 'SHOPPING_ELEMENT:90400500',
  forceTruckOverride: 'SHOPPING_ELEMENT:90400498',
  noNameDevice: "SHOPPING_ELEMENT:20400620"
}

export const defaultIPSelection = 'Select Justification'
export const staticIpJustifications = {
  select: defaultIPSelection,
  ftp: 'FTP Server',
  sip: 'SIP Proxy Server',
  atm: 'ATM',
  mail: 'Mail Server',
  cam: 'Security Camera',
  vpn: 'VPN Application',
  other: 'Other'
}

export const staticIpJustificationCounts = {
  [internetConfigurationItems.noStaticIp]: 0,
  [internetConfigurationItems.thirteenStaticIps]: 13,
  [internetConfigurationItems.fiveStaticIps]: 5,
  [internetConfigurationItems.oneStaticIp]: 1
}

export const validSecurityEdgeEquipmentIds = [
  internetConfigurationItems.docsis,
  internetConfigurationItems.businessWirelessGateway,
  internetConfigurationItems.internetEquipmentPackage,
  internetConfigurationItems.comcastProvidedEquipment,
  'SHOPPING_ELEMENT:20500099', // Alternative Comcast Equip Id
  'SHOPPING_ELEMENT:20500100' // Alternative Comcast Equip Id
]

// Voice
// Unique line item types
export const voiceLineItemTypes = {
  tollFree: 'VOICE_TOLL_FREE',
  huntGroup: 'VOICE_HUNT_GROUP',
  voiceMail: 'VOICE_MAIL',
  activationFee: 'VOICE_ACTIVATION_FEE',
  tollFreeActivationFee: 'TOLL_FREE_ACTIVATION_FEE',
  directoryListing: 'VOICE_DIRECTORY_LISTING',
  voiceEquipment: 'VOICE_EQUIPMENT',
  voiceLines: 'VOICE_LINE_TYPE',
  additionalInfo: 'additionalInfo',
  directoryListingInfo: 'directoryListingInfo'
}

export const voiceItemIds = {
  mobilityLine: 'SHOPPING_ELEMENT:30100035',
  basicLine: 'SHOPPING_ELEMENT:30100039',
  fullFeaturedLine: 'SHOPPING_ELEMENT:63003',
  tollFreeLine: 'SHOPPING_ELEMENT:3071',
  publishedDirListing: 'SHOPPING_ELEMENT:22010',
  activationFee: 'SHOPPING_ELEMENT:30400115',
  tollFreeActivationFee: 'SHOPPING_ELEMENT:30400182'
}

export const voiceLineGroupTypes = {
  voiceLines: 'voiceLines',
  tollFree: 'tollFree'
}

export const voiceLineTypes = {
  mobility: 'Mobility',
  basicLine: 'Basic',
  fullFeatured: 'FF',
  tollFreeLine: 'tollFree'
}

export const voiceRadioFieldNames = ['isBTN', 'isDLN']

export const voiceSections = {
  configOptions: 1,
  configLines: 2,
  configListings: 3,
  configValidated: 4
}

export const voiceLineTemplates = {
  mobility: {
    lineType: voiceLineTypes.mobility,
    lineName: 'Mobility Line',
    voiceLineGroup: voiceLineGroupTypes.voiceLines
  },
  basicLine: {
    lineType: voiceLineTypes.basicLine,
    lineName: 'Basic Line',
    voiceLineGroup: voiceLineGroupTypes.voiceLines
  },
  fullFeatured: {
    lineType: voiceLineTypes.fullFeatured,
    lineName: 'Full Featured Line',
    voiceLineGroup: voiceLineGroupTypes.voiceLines
  },
  tollFreeLine: {
    lineType: voiceLineTypes.tollFreeLine,
    lineName: 'Toll Free Line',
    voiceLineGroup: voiceLineGroupTypes.tollFree
  }
}

export const voiceConfigOptionsIds = {
  providers: '8102-125',
  callingAreas: '3071-303'
}

export const voiceAddOnFlags = {
  voiceMail: 'isVoiceMailOnLine',
  huntGroup1: 'HG1',
  huntGroup2: 'HG2'
}

export const voiceLineAddOnConfigurations = {
  [voiceAddOnFlags.voiceMail]: voiceLineItemTypes.voiceMail,
  [voiceAddOnFlags.huntGroup1]: voiceLineItemTypes.huntGroup,
  [voiceAddOnFlags.huntGroup2]: voiceLineItemTypes.huntGroup
}

export const PORTED_NUMBER = 'portedNumber'

export const OTHER_PROVIDER = 'OTHER'

// Video
export const videoLineItemTypes = {
  miniMDTA: 'MINI_MDTA_EQUIP',
  mDTA: 'MDTA_EQUIP',
  miniMDTAInstall: 'MINI_MDTA_INSTALL',
  mDTAInstall: 'MDTA_INSTALL',
  x1: 'X1_PLATFORM',
  primaryOutlet: 'PRIMARY_TV_EQUIP',
  additionalOutlets: 'TV_EQUIP_ADDITIONAL',
  tvEquipment: 'TV_EQUIP',
  hdFee: 'HD_TECH_FEE',
  canalesSelecto: 'CANALES_SELECTO',
  russianChannel: 'RUSSIAN_CHANNEL',
  italianChannel: 'ITALIAN_CHANNEL',
  musicChannel: 'MUSIC_CHANNEL',
  neoCricket: 'NEO_CHANNEL',
  setAsia: 'SET_ASIA_CHANNEL',
  tvGlobo: 'TV_GLOBO',
  tvJapan: 'TV_JAPAN',
  ccTV4: 'CCTV-4',
  ctiZhong: 'CTI-Zhong',
  sportsChannel: 'SPORTS_CHANNEL',
  zeeTv: 'ZEE_TV',
  publicView: 'PUBLIC_VIEW'
}

export const ignoredVideoTypes = [
  videoLineItemTypes.miniMDTA,
  videoLineItemTypes.mDTA,
  videoLineItemTypes.miniMDTAInstall,
  videoLineItemTypes.mDTAInstall,
  videoLineItemTypes.x1,
  videoLineItemTypes.primaryOutlet,
  videoLineItemTypes.additionalOutlets,
  videoLineItemTypes.hdFee,
  videoLineItemTypes.publicView,
  videoLineItemTypes.tvEquipment
]

export const videoConfigurationItems = {
  primaryOutletSd: 'SHOPPING_ELEMENT:10500024',
  primaryOutletSdAdaptor: 'SHOPPING_ELEMENT:40032',
  primaryOutletHd: 'SHOPPING_ELEMENT:2315',
  primaryOutletHdAdaptor: 'SHOPPING_ELEMENT:10500078',
  miniMDTA: 'SHOPPING_ELEMENT:40032',
  mDTA: 'SHOPPING_ELEMENT:10500241',
  miniMDTAInstallFee: 'SHOPPING_ELEMENT:10400593',
  mDTAInstallFee: 'SHOPPING_ELEMENT:10400592',
  additionalOutletDtaSd: 'SHOPPING_ELEMENT:40032',
  additionalOutletStbSd: 'SHOPPING_ELEMENT:10500024',
  additionalOutletDtaHd: 'SHOPPING_ELEMENT:10500078',
  additionalOutletStbHd: 'SHOPPING_ELEMENT:2315',
  x1: 'SHOPPING_ELEMENT:10400642',
  hdFee: 'SHOPPING_ELEMENT:23019',
  noFoxSportSelection: 'SHOPPING_ELEMENT:90400033',
  noBigTenSelection: 'SHOPPING_ELEMENT:90400033',
  noAttSportSelection: 'SHOPPING_ELEMENT:90400073',
  sportsPack: 'SHOPPING_ELEMENT:2203',
  canalesSelecto: 'SHOPPING_ELEMENT:200000368',
  tvJapan: 'SHOPPING_ELEMENT:3246',
  tvGlobo: 'SHOPPING_ELEMENT:3245',
  russianChannel: 'SHOPPING_ELEMENT:3206',
  italianChannel: 'SHOPPING_ELEMENT:23004',
  musicChannel: 'SHOPPING_ELEMENT:2123',
  neoCricket: 'SHOPPING_ELEMENT:20140',
  setAsia: 'SHOPPING_ELEMENT:23006',
  ccTV4: 'SHOPPING_ELEMENT:23000',
  ctiZhong: 'SHOPPING_ELEMENT:20155',
  zeeTv: 'SHOPPING_ELEMENT:3260',
  noPrimaryOutlet: 'SHOPPING_ELEMENT:10500158',
  publicView: 'SHOPPING_ELEMENT:10400862'
}

export const videoConfigurationDescriptions = {
  miniMDTA: 'Number of Outlets 10 to 50',
  mDTA: 'Number of Outlets 51 to 100'
}

export const nonAdditionalProgramTypes = [
  'lineOfBusiness',
  'items',
  'AdditionalOptions',
  'configuredItems',
  videoLineItemTypes.tvEquipment,
  videoLineItemTypes.miniMDTA,
  videoLineItemTypes.mDTA,
  videoLineItemTypes.x1,
  videoLineItemTypes.additionalOutlets,
  videoLineItemTypes.miniMDTAInstall,
  videoLineItemTypes.mDTAInstall,
  videoLineItemTypes.hdFee,
  videoLineItemTypes.publicView
]

export const additionalOutletIds = [
  videoConfigurationItems.additionalOutletDtaSd,
  videoConfigurationItems.additionalOutletStbSd,
  videoConfigurationItems.additionalOutletDtaHd,
  videoConfigurationItems.additionalOutletStbHd
]

export const hdOutletsIds = [
  videoConfigurationItems.primaryOutletHd,
  videoConfigurationItems.additionalOutletStbHd
]

// SmartOffice Section
export const officeLineItemTypes = {
  indoorCameras: 'INDOOR_CAMERAS',
  outdoorCameras: 'OUTDOOR_CAMERAS',
  cameraInstall: 'BIZ_EQUIP_INSTALL',
  cloudStorage30: 'BIZ_STORAGE_30',
  cloudStorage60: 'BIZ_STORAGE_60',
  cloudStorageOptIn60: 'BIZ_STORAGE_OPT_IN',
  installmentPlan: '3_MONTH_PAYMENTS'
}

export const officeConfigurationItems = {
  indoorCameras: 'SHOPPING_ELEMENT:10500160',
  outdoorCameras: 'SHOPPING_ELEMENT:10500159',
  cameraInstall5: 'SHOPPING_ELEMENT:10400581',
  cameraInstall10: 'SHOPPING_ELEMENT:10400581',
  cameraInstall15: 'SHOPPING_ELEMENT:10400581',
  cloudStorage30: 'SHOPPING_ELEMENT:10400629',
  cloudStorage60: 'SHOPPING_ELEMENT:10400631',
  cloudStorageOptIn60: 'SHOPPING_ELEMENT:10400660',
  installment3Months: 'SYNTHETIC_ID:OFFICE_3_MONTH_PAYMENTS'
}

// Core
export const lineItemTypes = {
  configuredItems: 'configuredItems'
}

export const validateLineItem = {
  [videoLineItemTypes.tvEquipment]: 'VALIDATE_OUTLETS',
  [videoLineItemTypes.x1]: 'VALIDATE_X1',
  [voiceLineItemTypes.voiceLines]: 'VALIDATE_VOICE_QUANTITY',
  [voiceLineItemTypes.huntGroup]: 'VALIDATE_VOICE_QUANTITY',
  [voiceLineItemTypes.tollFree]: 'VALIDATE_VOICE_QUANTITY',
  [voiceLineItemTypes.voiceMail]: 'VALIDATE_VOICE_QUANTITY',
  [videoLineItemTypes.additionalOutlets]: 'VALIDATE_ADDITIONAL_OUTLETS'
}

// TODO: See if we can move to backend
export const NO_SELECTION_IDS = [
  videoConfigurationItems.noAttSportSelection,
  videoConfigurationItems.noBigTenSelection,
  videoConfigurationItems.noFoxSportSelection,
  internetConfigurationItems.noWifi,
  internetConfigurationItems.noStaticIp,
  internetConfigurationItems.noConnectionPro,
  internetConfigurationItems.noSecurityEdge
]

export const HIDDEN_CART_IDS = [
  ...NO_SELECTION_IDS,
  officeConfigurationItems.cloudStorageOptIn60,
  videoConfigurationItems.hdFee,
  internetConfigurationItems.noTruckRollOverride,
  internetConfigurationItems.forceNoTruckOverride,
  internetConfigurationItems.forceTruckOverride,
  internetConfigurationItems.noNameDevice
]

// Mapped names for Cart Summary
export const cartSummaryNames = {
  'SHOPPING_ELEMENT:2132': 'Directory Listing Choice', // Non-Listed - Commercial
  'SHOPPING_ELEMENT:2133': 'Directory Listing Choice', // Non-Published
  'SHOPPING_ELEMENT:22010': 'Directory Listing Choice', // Published with Optional ILEC Listing
  'SHOPPING_ELEMENT:30400035': 'Voicemail',
  'SHOPPING_ELEMENT:30400115': 'Activation Fee'
}

export const lineItemTypeLookups = {
  // Internet
  [internetLineItemTypes.wifi]: {
    'SHOPPING_ELEMENT:20400090': internetLineItemTypes.wifiAP
  },
  [internetLineItemTypes.connectionPro]: {
    [internetConfigurationItems.connectionProExtBat]:
      internetLineItemTypes.connectionProExtBat
  },
  [internetLineItemTypes.securityEdge]: {
    [internetConfigurationItems.securityEdgeExtCov]:
      internetLineItemTypes.securityEdgeExtCov
  },

  // TV
  TV_EQUIP: {
    [videoConfigurationItems.primaryOutletSd]: videoLineItemTypes.primaryOutlet,
    [videoConfigurationItems.primaryOutletHd]: videoLineItemTypes.primaryOutlet,
    [videoConfigurationItems.additionalOutletDtaHd]:
      videoLineItemTypes.tvEquipment,
    [videoConfigurationItems.additionalOutletDtaSd]:
      videoLineItemTypes.tvEquipment,

    [videoConfigurationItems.miniMDTAInstallFee]:
      videoLineItemTypes.miniMDTAInstall,
    [videoConfigurationItems.mDTAInstallFee]: videoLineItemTypes.mDTAInstall,
    [videoConfigurationItems.miniMDTA]: videoLineItemTypes.miniMDTA,
    [videoConfigurationItems.mDTA]: videoLineItemTypes.mDTA
  },
  TV_EQUIP_MDTA: {
    [videoConfigurationItems.miniMDTA]: videoLineItemTypes.miniMDTA,
    [videoConfigurationItems.mDTA]: videoLineItemTypes.mDTA
  },

  // VOICE
  NONE: {
    // TODO remove when supported everywhere
    // VOICE_LINES
    [voiceItemIds.mobilityLine]: voiceLineItemTypes.voiceLines,
    [voiceItemIds.basicLine]: voiceLineItemTypes.voiceLines,
    [voiceItemIds.fullFeaturedLine]: voiceLineItemTypes.voiceLines
  },
  VOICE_TOLL_FREE: {
    [voiceItemIds.tollFreeActivationFee]:
      voiceLineItemTypes.tollFreeActivationFee
  },

  // Smart Office
  BIZ_STORAGE: {
    [officeConfigurationItems.cloudStorageOptIn60]:
      officeLineItemTypes.cloudStorageOptIn60,
    [officeConfigurationItems.cloudStorage60]:
      officeLineItemTypes.cloudStorage60,
    [officeConfigurationItems.cloudStorage30]:
      officeLineItemTypes.cloudStorage30
  },
  BIZ_CAMERA: {
    [officeConfigurationItems.indoorCameras]: officeLineItemTypes.indoorCameras,
    [officeConfigurationItems.outdoorCameras]:
      officeLineItemTypes.outdoorCameras
  }
}

export const NO_SELECTION = 'no-selection'

export const MODIFY_QUOTE =
  'You are attempting to modify the quote after Document Acceptance has been completed.  Any changes will expire accepted documentation and document acceptance will be required. Are you sure you want to proceed?'
export const MODIFY_QUOTE_PENDING_ESIG =
  '​​​​​​​You are attempting to modify the quote while eSignature is pending.  Any changes will expire accepted documentation and document acceptance will be required. Are you sure you want to proceed?'

export const VOICE_LINE_API_ERROR =
  'An error has occurred retrieving CRCP details. Please provide State, Rate Center, NPA and NXX for CRCP lines.'
export const REQUIRED_CRCP_ERROR =
  'Please provide State, Rate Center, NPA and NXX when configuring CRCP for new or ported lines'
export const RATE_CENTER_CHARACTER_ERROR =
  'Rate Center must have a minimum of 1 and maximum of 10 characters. Rate Center must be made of alpha-numeric characters.'
export const CRCP_INELIGIBLE_ERROR =
  '<PHONE_NUMBER> is not CRCP eligible. If porting is still required, utilize RCF in Bundle Builder.'
export const crcpStatus = {
  IN_RATE_CENTER: 'IN_RATE_CENTER',
  CRCP_ELIGIBLE: 'CRCP_ELIGIBLE',
  CRCP_NOT_ELIGIBLE: 'CRCP_NOT_ELIGIBLE'
}
export const PORTING_ELIGIBLE = '<PHONE_NUMBER> is eligible for porting.'
export const CRCP_ELIGIBLE = '<PHONE_NUMBER> is eligible for CRCP.'
export const CRCP_RESTRICTED =
  'CRCP is restricted for State <STATE_ABBREVIATION>, TN from this State is restricted for Foreign Exchange outside of the TN Rate Center.'

export const REVERT_EXISTING_CONFIG = 'Revert to Existing Config'
export const CLEAR_CONFIG = 'Clear Config'
export const RETIREMENT_BANNER_NOTIFICATION = 'Attention: The Quick Quote functionality in Frictionless will be retired as of 5/22/24.'
export const weightedLobs = {
  [configTypes.internet]: 1,
  [configTypes.voice]: 2,
  [configTypes.tv]: 3,
  [configTypes.office]: 4
}

export const smartOfficeInstallationFee = 'SmartOffice Installation Fee'

export const videoDwellTypes = {
  public: 'public',
  private: 'private'
}

export const byPassDlnValidationIds = [
  'SHOPPING_ELEMENT:2132', // non listed
  'SHOPPING_ELEMENT:2133' // non published
]

export const configMoveStatusIds = {
  doNotDisconnect: 'doNotDisconnect',
  tempOverlapOfService: 'temporaryOverlapOfService',
  transfer: 'transfer',
  telephoneGrid: 'telephoneGrid'
}

export const staticIpMove = 'STATIC_IP_MOVE'

export const tollFreeMoveLineTemplate = [
  {
    id: 'noChange',
    name: 'No Change',
    isSelected: false
  },
  {
    id: 'transfer',
    name: 'Transfer',
    isSelected: false
  }
]

export const moveVoiceNewLine = {
  id: 'newNumber',
  name: 'New Number'
}

export const truckRollOverideTypes = {
  'SHOPPING_ELEMENT:90400501': 'No Override',
  'SHOPPING_ELEMENT:90400500': 'Force No Truck',
  'SHOPPING_ELEMENT:90400498': 'Force Truck Roll Override'
}

export const selectedInternetSpeedTypes = {
  fdx: 'FDX',
  genesis: 'Genesis'
}
